import './App.css';
import RouteApp from './Routes/RouteApp';

function App() {
  return (
    <RouteApp />
  );
}

export default App;
